<template>
  <b-card title="Persentase KBM">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
                  <b-form-group label="Tahun Akademik" label-for="academic_year_id"
                    :state="errors.length > 0 ? false : null">
                    <v-select id="academic_year_id" v-model="dataParent.academic_year_id"
                      :reduce="(academic_year_id) => academic_year_id.id" placeholder="Pilih Tahun Akademik"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listTA" label="name" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="academic_class_id" rules="required">
                  <b-form-group label="Kelas" label-for="academic_class_id" :state="errors.length > 0 ? false : null">
                    <v-select id="academic_class_id" v-model="dataParent.academic_class_id"
                      :reduce="(academic_class_id) => academic_class_id.id" placeholder="Pilih Kelas"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass" label="class_desc" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="semester" rules="required">
                  <b-form-group label="Semester" label-for="semester" :state="errors.length > 0 ? false : null">
                    <v-select id="semester" v-model="dataParent.semester" :reduce="(semester) => semester.value"
                      placeholder="Pilih Semester" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="listSemester" label="text" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                    @click="GetAllData"><feather-icon icon="SearchIcon" />
                    View
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
    <table class="table" id="generatetable">
      <thead>
        <tr>
          <th>No</th>
          <th>Mata Pelajaran</th>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>6</th>
          <th>7</th>
          <th>8</th>
          <th>9</th>
          <th>10</th>
          <th>11</th>
          <th>12</th>
          <th>13</th>
          <th>14</th>
          <th>15</th>
          <th>16</th>
          <th>17</th>
          <th>18</th>
          <th>19</th>
          <th>20</th>
          <th>21</th>
          <th>22</th>
          <th>23</th>
          <th>24</th>
          <th>25</th>
          <th>26</th>
          <th>27</th>
          <th>28</th>
          <th>29</th>
          <th>30</th>
          <th>31</th>
          <th>Persentase Kehadiran</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import axios from 'axios';
import $ from 'jquery';

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mounted() {
    $('#generatetable').DataTable({
      processing: true,
      responsive: true,
      scrollX: true,
    });
  },
  data: function () {
    return {
      products: [],
      dataParent: {
        academic_year_id: '',
        academic_class_id: '',
        semester: '',
      },
      DataProduct: [],
      listSiswa: [],
      listTA: [],
      listClass: [],
      listSemester: [
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: '5', text: '5' },
        { value: '6', text: '6' },
        { value: '7', text: '7' },
        { value: '8', text: '8' },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getsiswa()
    this.getTA()
    this.getClass()
  },
  methods: {
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getTA() {
      try {
        const response = await this.$http.get('/academicyears')
        this.listTA = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getClass() {
      try {
        const response = await this.$http.get('/masterclasses')
        this.listClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Employee.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },

    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async GetAllData() {
      try {
        var nisn = document.getElementById('nisn');
        const response = await this.$http.get('/academicteachingagendas-recap?academic_year_id=4nvoeygeoxw3zpra&academic_class_id=4nvoeygeoxw3zpra&semester=1')
        var DataRespone = response.data;
        var number = 1;
        var RecapTemp = [];
        console.log(DataRespone);
        $.each(DataRespone, function (i, item) {
          var DataDetail = DataRespone[i].details;
          console.log(DataDetail);
          var JsonData = {
            "no": number,
            "name": DataRespone[i].name,
            "1": DataRespone[i].data[1],
            "2": DataRespone[i].data[2],
            "3": DataRespone[i].data[3],
            "4": DataRespone[i].data[4],
            "5": DataRespone[i].data[5],
            "6": DataRespone[i].data[6],
            "7": DataRespone[i].data[7],
            "8": DataRespone[i].data[8],
            "9": DataRespone[i].data[9],
            "10": DataRespone[i].data[10],
            "11": DataRespone[i].data[11],
            "12": DataRespone[i].data[12],
            "13": DataRespone[i].data[13],
            "14": DataRespone[i].data[14],
            "15": DataRespone[i].data[15],
            "16": DataRespone[i].data[16],
            "17": DataRespone[i].data[17],
            "18": DataRespone[i].data[18],
            "19": DataRespone[i].data[19],
            "20": DataRespone[i].data[20],
            "21": DataRespone[i].data[21],
            "22": DataRespone[i].data[22],
            "23": DataRespone[i].data[23],
            "24": DataRespone[i].data[24],
            "25": DataRespone[i].data[25],
            "26": DataRespone[i].data[26],
            "27": DataRespone[i].data[27],
            "28": DataRespone[i].data[28],
            "29": DataRespone[i].data[29],
            "30": DataRespone[i].data[30],
            "31": DataRespone[i].data[31],
            "percentage": DataRespone[i].percentage,
          }
          number++;
          RecapTemp.push(JsonData);
        });
        console.log(RecapTemp);
        this.DataProduct = this.RecapTemp;
        $('#generatetable').DataTable().destroy();
        var myTable = $('#generatetable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "scrollX": true,
          "data": [],
          "columns": [{
            "title": "No",
            "data": "no"
          }, {
            "title": "Mata Pelajaran",
            "data": "name"
          },
          {
            "title": "1",
            "data": "1"
          }, {
            "title": "2",
            "data": "2"
          }, {
            "title": "3",
            "data": "3"
          }, {
            "title": "4",
            "data": "4"
          },
          {
            "title": "5",
            "data": "6"
          },
          {
            "title": "6",
            "data": "6"
          },
          {
            "title": "7",
            "data": "7"
          },
          {
            "title": "8",
            "data": "8"
          },
          {
            "title": "9",
            "data": "9"
          },
          {
            "title": "10",
            "data": "10"
          },
          {
            "title": "11",
            "data": "11"
          },
          {
            "title": "12",
            "data": "12"
          },
          {
            "title": "13",
            "data": "13"
          },
          {
            "title": "14",
            "data": "14"
          },
          {
            "title": "15",
            "data": "15"
          },
          {
            "title": "16",
            "data": "16"
          },
          {
            "title": "17",
            "data": "17"
          },
          {
            "title": "18",
            "data": "18"
          },
          {
            "title": "19",
            "data": "19"
          },
          {
            "title": "20",
            "data": "20"
          },
          {
            "title": "21",
            "data": "21"
          },
          {
            "title": "22",
            "data": "22"
          },
          {
            "title": "23",
            "data": "24"
          },
          {
            "title": "24",
            "data": "24"
          },
          {
            "title": "25",
            "data": "25"
          },
          {
            "title": "26",
            "data": "26"
          },
          {
            "title": "27",
            "data": "27"
          },
          {
            "title": "28",
            "data": "28"
          },
          {
            "title": "29",
            "data": "29"
          },
          {
            "title": "30",
            "data": "30"
          },
          {
            "title": "31",
            "data": "31"
          },
          {
            "title": "Persentase Kehadiran",
            "data": "percentage"
          }],
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          console.log(value);
          myTable.row.add(value);
        });
        myTable.draw();
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(this.err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
